<template>
  <div>
    <div
      v-if="type === 'edit' && !editBusinessPopulated"
      class="row justify-center items-center"
    >
      <q-spinner color="primary" size="3em" />
    </div>
    <div v-else>
      <q-form @submit="$emit('submit', business, thresholds)">
        <q-card flat bordered>
          <q-card-section>
            <h5>Business Details</h5>
          </q-card-section>
          <q-card-section>
            <div class="q-gutter-y-md">
              <q-input
                filled
                v-model="business.name"
                label="Business Name"
                lazy-rules
                :rules="[
                  val => (val && val.length > 1) || 'Enter an Business Name.'
                ]"
                :disable="submitting"
              />
            </div>
          </q-card-section>
        </q-card>

        <div v-if="businessId == '1'">
          <q-card flat bordered class="q-mt-md">
            <q-card-section>
              <h5>Custom Configurations</h5>
            </q-card-section>
            <q-card-section>
              <h6 class="q-mb-md">TQ Thresholds</h6>
              <template
                v-for="threshold in thresholds"
                v-bind:key="threshold.brand_id"
              >
                <q-input
                  filled
                  mask="#.#"
                  fill-mask="0"
                  reverse-fill-mask
                  v-model="threshold.value"
                  :label="threshold.brand_name"
                  lazy-rules
                  :rules="[
                    val =>
                      (isFloat(val) && val >= 0 && val <= 10) ||
                      'Enter a Threshold Value'
                  ]"
                />
              </template>
              <small class="text-grey-7"
                >Set 3-Day TQ Minimum to 0.0 if no threshold wants to be
                set.</small
              >
            </q-card-section>
          </q-card>
        </div>

        <FormActionsSection>
          <template v-slot:primary-actions>
            <q-btn
              :label="type === 'create' ? 'Create Business' : 'Update Business'"
              type="submit"
              color="primary"
              :disable="submitting"
              :loading="submitting"
            />
            <q-btn
              flat
              label="Cancel"
              to="/manage/businesses"
              color="subtle"
              :disable="submitting"
            />
          </template>
          <template v-slot:secondary-actions>
            <q-btn
              v-if="type === 'edit'"
              round
              flat
              color="subtle"
              icon="delete"
              @click="promptDeleteBusiness(business)"
            >
              <q-tooltip anchor="center left" self="center right">
                Delete Business
              </q-tooltip>
            </q-btn>
          </template>
        </FormActionsSection>
      </q-form>
    </div>
  </div>
</template>

<script>
import { Dialog } from "quasar";
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete";
import FormActionsSection from "@/components/UI/FormActionsSection";
import axios from "axios";

export default {
  name: "BusinessForm",
  components: { FormActionsSection },
  props: {
    type: {
      type: String,
      required: true
    },
    businessId: {
      type: String,
      required: false
    },
    submitting: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      business: {
        id: "0",
        name: ""
      },
      thresholds: []
    };
  },
  computed: {
    editBusinessPopulated() {
      return this.business.id !== "0";
    }
  },
  mounted() {
    if (this.type === "edit" && this.businessId.length > 0) {
      this.getBusiness(this.businessId);
    }
    if (this.businessId == "1") {
      this.getThresholds();
    }
  },
  methods: {
    getBusiness(businessId) {
      this.$store
        .dispatch("businesses/GET_REQUEST", {
          params: {
            id: businessId
          }
        })
        .then(data => {
          if (data.length > 0) {
            let business = data[0];
            this.business = {
              id: business.id,
              name: business.name
            };
            if (this.type === "create") {
              this.business.id = "0";
            }
          }
        });
    },
    promptDeleteBusiness(business) {
      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor: business.name
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        this.$store
          .dispatch("businesses/POST_REQUEST", {
            endpoint: "delete",
            params: { id: business.id }
          })
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            dialog.hide();
            this.$router.push({ path: "/manage/businesses" });
          })
          .catch(() => {
            this.triggerActionOutcomeAlert("delete", false);
          })
          .finally(() => {
            dialog.submitting = false;
          });
      });
    },
    getThresholds() {
      this.loading = true;
      axios
        .get("https://api.jumpfeed.com/businesses/medianet/thresholds")
        .then(response => {
          this.thresholds = response.data.data;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
